import React, { Component } from "react";
 
class Four extends Component {
  render() {
    return (
      <div>
        <h2>Fourth Page</h2>
        <p>This is just an example of adding a page to the React Router tutorial.</p>
      </div>
    );
  }
}
 
export default Four;