import React, { Component } from "react";
import State from "./State";

 
class Home extends Component {
  render() {
    return (
      <State/>
    );
  }
}
 
export default Home;