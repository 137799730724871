import React, { Component } from "react";
import Timer from "./Timer";
 
class Stopwatch extends Component {
  render() {
    return (
        <Timer/>
    );
  }
}
 
export default Stopwatch;