import React, { Component } from "react";
 
class Contact extends Component {
  render() {
    return (
      <div>
        <h2>Contact Me</h2>

        <form action="/action_page.php">
          First name:<br />
          <input type="text" name="firstname" value="Mickey" />
          <br />
          Last name:<br />
          <input type="text" name="lastname" value="Mouse" />
          <br /><br />
          <input type="submit" value="Submit" />
        </form>
        <p class="small">This form doesn't really work.</p>
      </div>
    );
  }
}
 
export default Contact;