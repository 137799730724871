import React, { Component } from "react";
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import Home from "./Home";
import Stopwatch from "./Stopwatch";
import Contact from "./Contact";
import Four from "./Four";
import logo from './logo.png';
 
class Main extends Component {
  render() {
    return (
      <HashRouter>
        <div>
        <header>
            <img src={logo} class="logo" alt="Logo" />
            
            <ul className="header">
                <li><NavLink exact to="/">Welcome</NavLink></li>
                <li><NavLink to="/stopwatch">Stopwatch</NavLink></li>
                <li><NavLink to="/contact">Contact</NavLink></li>
                <li><NavLink to="/four">Four</NavLink></li>
            </ul>
        
            <div className="content">
                <Route exact path="/" component={Home}/>
                <Route path="/stopwatch" component={Stopwatch}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/four" component={Four}/>
            </div>
        </header>
        </div>
      </HashRouter>
    );
  }
}
 
export default Main;

