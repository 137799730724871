import React, { Component } from "react";

class State extends Component {
  constructor() {
    super();
    this.state = { text: '' };
  }
  update(e) {
    this.setState({
      text: e.target.value
    });
  }
  render() {
    return (
      <div>
        <p class="small">Type your name for some self-affirmation</p>
        <input 
          type="text" 
          placeholder="Enter..." 
          onChange={this.update.bind(this)} 
          />
        <h1>{this.state.text} is a really cool person!</h1>
      </div>
    );
  }
}

export default State;
